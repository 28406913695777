import 'magnific-popup';


var default_song_list = [
  {
    "track_title": "trigger warning",
    "spotify_uri": "spotify:track:6v72z6eFessDmcm3a2ZgPk"
  },
  {
    "track_title": "bring u down",
    "spotify_uri": "spotify:track:6ZavW4JaYViGktEQhCiiyQ"
  },
  {
    "track_title": "lonely",
    "spotify_uri": "spotify:track:2Lm0zTyVto9JsTwcjIuZcw"
  },
  {
    "track_title": "thank god",
    "spotify_uri": "spotify:track:1ISqxcKx6GHXjomB4NfkpF"
  },
  {
    "track_title": "Meet Me At Our Spot",
    "spotify_uri": "spotify:track:36d4GIYKnTr37URimc6FSP"
  },
  {
    "track_title": "karma",
    "spotify_uri": "spotify:track:5SCBS0esfqeL54ZCw6Revb"
  },
  {
    "track_title": "evergreen",
    "spotify_uri": "spotify:track:4XxUka4jIa0girDclOY4iA"
  },
  {
    "track_title": "butterfly fields",
    "spotify_uri": "spotify:track:2nXtGLBgSu60upR05CGEBN"
  },
  {
    "track_title": "golden years",
    "spotify_uri": "spotify:track:4xP5bdGofMzMQyH1TDFPp8"
  },
  {
    "track_title": "OH WELL",
    "spotify_uri": "spotify:track:2sfYIKz3J8eZkBdTh25KyK"
  },
  {
    "track_title": "avalanche",
    "spotify_uri": "spotify:track:1RlmAzI27AlIpvoRN3u6Iy"
  },
  {
    "track_title": "good things take time",
    "spotify_uri": "spotify:track:2VkPfL3qQ2adxShD3rNvoV"
  },
  {
    "track_title": "wake up",
    "spotify_uri": "spotify:track:4J7MeU11RBJII3S3aapd7i"
  },
  {
    "track_title": "i think too much",
    "spotify_uri": "spotify:track:5Zyf5wqwNRfm2xqpLSzoM0"
  },
  {
    "track_title": "crowded room",
    "spotify_uri": "spotify:track:18NqWXl1AeV74JDZs1RHlX"
  },
  {
    "track_title": "make or break up",
    "spotify_uri": "spotify:track:2U5YjyW90TnbX8gM4oflWB"
  },
  {
    "track_title": "time of our lives",
    "spotify_uri": "spotify:track:4DdeaDqAqr6k0Rm9bSuVa6"
  },
  {
    "track_title": "paper thin",
    "spotify_uri": "spotify:track:0SAqWrMvHHXvefMckjfMWM"
  },
  {
    "track_title": "head first",
    "spotify_uri": "spotify:track:42GWWwsN6shQUzIiJioNtD"
  },
  {
    "track_title": "bright side of the moon",
    "spotify_uri": "spotify:track:5r4G5w8ujwefEnrCT5uzoX"
  },
  {
    "track_title": "hungover sunday",
    "spotify_uri": "spotify:track:2zY8W4sR9Zzf7M34IZbq6n"
  },
  {
    "track_title": "breaking all the rules",
    "spotify_uri": "spotify:track:6tOSnnwHqg14bFMmXBOFYT"
  },
  {
    "track_title": "call me your love",
    "spotify_uri": "spotify:track:1jYp3erCalzBtKZSgl78a9"
  },
  {
    "track_title": "heavy snow",
    "spotify_uri": "spotify:track:5ixGkl3l66MHysCDZOL6OC"
  },
  {
    "track_title": "love ride",
    "spotify_uri": "spotify:track:6plrtJqhiRa8W49FCiyE3Q"
  },
  {
    "track_title": "superstars",
    "spotify_uri": "spotify:track:3RyCRKC0bdFrd0qyLEzogT"
  },
  {
    "track_title": "sweet home",
    "spotify_uri": "spotify:track:6y0Oy6CEWDYLvcIIjthiyv"
  },
  {
    "track_title": "hearts of gold",
    "spotify_uri": "spotify:track:577yyt9WMqvhj2yYPt2BWw"
  },
  {
    "track_title": "someone new",
    "spotify_uri": "spotify:track:2XPoZyB6dtZoT0Anku2Ukz"
  },
  {
    "track_title": "love ride (acoustic)",
    "spotify_uri": "spotify:track:6ssv13svD7Kx5NakuQXwkY"
  },
  {
    "track_title": "drifter",
    "spotify_uri": "spotify:track:0NeuK91dYlzLJJejKehOxo"
  },
  {
    "track_title": "Done from the Start",
    "spotify_uri": "spotify:track:0DpCrXFRwEYVl8dmlj4fzr"
  },
  {
    "track_title": "By Myself",
    "spotify_uri": "spotify:track:6iRTYLWbxXtUz00KQIWiTN"
  },
  {
    "track_title": "Dying Alive",
    "spotify_uri": "spotify:track:4M16nvTxhR9VCrfj1JMc6I"
  },
  {
    "track_title": "Fall for You",
    "spotify_uri": "spotify:track:7JKdB79MjDgH9GE72AChkY"
  }
]

jQuery( document ).ready(function($) {

    // //temp
    // $('body').removeClass('step1');
    // $('body').addClass('step2');

    $('.btn-connect').on('click', function(e){
        e.preventDefault()
        if ( typeof ae_js !== 'undefined' ) {
            if ( spotify_user_id === null ) {
                ae_js.trigger.authenticate( 'spotify', 'register' );
            } else {
                $('body').removeClass('step1');
                $('body').addClass('step2');
            }
        } else {
            alert( 'Appreciation Engine Not Yet Loaded!' );
        }
    })

    var list = $( '#search' ).find( '.box-inner>ul' );
	$.each( default_song_list, function ( index, song ) {
		var html = '<li data-track-id="' + song.spotify_uri + '">';
		html += '<span class="track-title">' + song.track_title + '</span>';
		html += '<div class="actions"><a href="#" class="add"><i class="fas fa-plus" aria-hidden="true"></i><span class="sr-only">Add</span></div>';
		html += '</li>';
		list.append( html );
	} );

	$( '#search ul' ).on( 'click', 'a.add', function ( e ) {
		e.preventDefault();
		var uri = $( this ).parents( 'li' ).attr( 'data-track-id' );
		var text = $( this ).parents( 'li' ).find( 'span.track-title' ).text();
		var html = '<li data-track-id="' + uri + '"> <span>' + text + '</span><div class="actions"><a href="#" class="remove"><i class="fas fa-minus" aria-hidden="true"></i><span class="sr-only">Remove</span></a></div></li> ';
		$( '#playlist ul' ).append( html ).sortable( );
	} );

	$( '#playlist ul' ).on( 'click', 'a.remove', function ( e ) {
		$( this ).parents( 'li' ).remove();
		e.preventDefault();
	} );

    $( '.save-btn' ).on( 'click', function ( e ) {
		e.preventDefault();
		spotifyApi.createPlaylist( spotify_user_id, { name: 'The Space Between Tour Dream Setlist' }, function ( err, playlist ) {
			if ( err ) {
				console.error( err );
				alert( 'An error has occured! Errno: 101' );
			} else {
				var tracks = [];

				$( '#playlist ul li' ).each( function () {
					var uri = $( this ).attr( 'data-track-id' );
					tracks.push( uri ); 
				} );

				spotifyApi.addTracksToPlaylist( playlist.id, tracks, function ( err ) {
					if ( err ) {
						console.error( err );
						alert( 'An error has occured! Errno: 102' );
					} else {

						var playlist_url = 'https://open.spotify.com/user/' + spotify_user_id + '/playlist/' + playlist.id;
            let pop = "<div class='popup'><p>thanks for submitting.<br>can't wait to see you soon <3</p><div class='share'><span>share your playlist</span><a target='_blank' href='https://www.facebook.com/sharer/sharer.php?u=" + playlist_url + "' aria-label='facebook'><i class='fab fa-facebook'></i></a><a target='_blank' href='https://twitter.com/intent/tweet?text=" + playlist_url + "' aria-label='twitter'><i class='fab fa-twitter'></i></a></div> </div>";
            $.magnificPopup.open({
              items: {
                src: pop,
                type: 'inline'
              }
            });
						jQuery.ajax( { 
							url: 'https://subs.sonymusicfans.com/submit',
							method: 'POST',
							xhrFields: { withCredentials: false },
							crossDomain: true,
							data: {
                field_email_address: ae_user.data.Email,
                form: '326866',
                default_mailing_list: 'a0S1p00000USUoeEAH',
                ae_member_id: ae_user.data.ID,
                ae_segment_id: '1972146',
                ae_brand_id: '4024153',
                source_channel: 'Spotify',
                ae_activities:'{"actions":{"presave":118867},"mailing_list_optins":{"a0S1p00000USUoeEAH":118868}}'
              },
							error: function () {
								alert( 'An error has occured! Errno: 103' );
							}
						} );
					}
				} );

			}
		} );
	} );

});